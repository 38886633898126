const isEnabled = () =>
  (window.IntercomIncludeInDevelopment ||
    window.environment === 'production') &&
  typeof Intercom === 'function' &&
  window.IntercomAppId;

exports.onInitialClientRender = () => {
  if (!isEnabled()) {
    return;
  }

  window.Intercom('boot', {
    app_id: window.IntercomAppId,
    hide_default_launcher:
      window.HideDefaultLauncher || window.location.pathname === '/koko',
    custom_launcher_selector: window.CustomLauncherSelector,
  });
};

exports.onRouteUpdate = function ({ location }) {
  if (!isEnabled()) {
    return;
  }

  // Update hide_default_launcher based on current path
  window.Intercom('update', {
    hide_default_launcher:
      window.HideDefaultLauncher || location.pathname === '/koko',
  });
};
